import React from "react";
import Card from "./card";

const Search = ({ markdown, query }) => {
  const blogPosts = markdown.edges;

  const filteredPosts = blogPosts.filter(({ node }) => {
    const { frontmatter } = node;

    const titleMatch = frontmatter.title.toLowerCase().includes(query.toLowerCase());
    const excerptMatch = frontmatter.excerpt.toLowerCase().includes(query.toLowerCase());

    return titleMatch || excerptMatch;
  });

  return (
    <div>
      <div>
        <h2 className="page-header">
          {filteredPosts.length > 0 ? "Search results " : "No results "}
          for "<strong>{query.replace("%20", " ")}</strong>"
        </h2>

        {filteredPosts.length > 0 && (
          <div className="flex-layout">
            <div className="cards">
              <h2 id="articles-title">Articles</h2>
              {filteredPosts.map(({ node }) => {
                return <Card key={node.id} slug={node.fields.slug} frontmatter={node.frontmatter} />;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Search;
