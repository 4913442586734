import React from "react";
import { Link } from "gatsby";

const Card = ({ frontmatter, slug }) => {
  const { featuredImage, title, excerpt } = frontmatter;

  return (
    <article className="card-styles">
      <Link to={slug}>
        <div
          className="card-image"
          style={{
            backgroundImage: `url(
              /images/${featuredImage.relativePath}
            )`,
          }}
        ></div>

        <div className="text-wrapper">
          <h3>{title}</h3>

          {excerpt && <p>{excerpt}</p>}
        </div>
      </Link>
    </article>
  );
};
export default Card;
