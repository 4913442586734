import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import Search from "../components/search";

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { fields: frontmatter___order }, filter: { fileAbsolutePath: { regex: "/pages/" } }) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              excerpt
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
                relativePath
              }
            }
          }
        }
      }
    }
  `);
  const [queryType, query] = props.location.search.split("=");

  if (queryType === "?s" && query.length > 0) {
    return (
      <Layout>
        <Search markdown={data.allMarkdownRemark} query={query} />
      </Layout>
    );
  } else {
    return (
      <Layout>
        <SEO title="Home" slug="/" />

        <div className="content-wrapper">
          <h2 id="articles-title">Popular links</h2>

          {data.allMarkdownRemark.edges.map(({ node }) => {
            return <Card key={node.id} slug={node.fields.slug} frontmatter={node.frontmatter} />;
          })}
        </div>
      </Layout>
    );
  }
};

export default IndexPage;
